import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from '@react-hook/media-query';
import { useRouter } from 'next/router';
// import {
//     disableBodyScroll,
//     enableBodyScroll,
//     clearAllBodyScrollLocks,
// } from 'body-scroll-lock';

// import { SmartLink } from '../SmartLink';
import { scrollToTargetOffset, setBodyNoScroll } from '../../utils/common';
import FilterSelect from '../FilterSelect';
import PeopleCard from '../Cards/PeopleCard';
import Pagination from '../Pagination';
import CustomSelect from '../CustomSelect';

import { getFilters, getPeoples, getPeoplesLoading } from '../../redux/people/people.selector';
import { addFilter, clearFilters, fetchPeoples } from '../../redux/people/people.action';

import style from './PeopleListing.module.scss';

const PeopleListing = ({ title, filters, peoples, copies }) => {
    const filtersRef = useRef(null);
    const elRef = useRef(null);
    const [filterOpen, setFilterOpen] = useState(false);
    const [sort, setSort] = useState('latest');
    const state = useSelector(state => state);
    const selectedFilters = getFilters(state);
    const peoplesFromApi = getPeoples(state);
    const peoplesLoading = getPeoplesLoading(state);
    const dispatch = useDispatch();
    const handleFilterSelect = e => {
        const type = e.currentTarget.name;
        const value = e.currentTarget.value;
        const label = e.currentTarget.getAttribute('data-label');
        // const checked = e.currentTarget.checked;
        // console.log(type);
        // console.log(value);
        // console.log(label);
        // console.log(checked);
        const obj = { type: type, value: value, label: label };
        if (value === 'all') {
            dispatch(clearFilters(type));
        } else {
            dispatch(addFilter(obj));
        }
        // dispatch(fetchPeoples(0, convertFiltersToString()));
    };

    // const [pageLoaded, setPageLoaded] = useState(false);
    const [peopleCache, setPeopleCache] = useState(peoples);
    const [filterCache, setFilterCache] = useState(selectedFilters);

    const sortSelect = [
        {
            label: 'LATEST',
            value: 'latest',
        },
        {
            label: 'A - Z',
            value: 'a_z',
        },
        {
            label: 'Z - A',
            value: 'z_a',
        },
    ];

    const removeFilter = e => {
        const type = e.currentTarget.getAttribute('data-type');
        const value = e.currentTarget.getAttribute('data-value');
        const label = e.currentTarget.getAttribute('data-label');
        const obj = { type: type, value: value, label: label };
        dispatch(addFilter(obj));
    };

    const clearFiltersClick = () => {
        dispatch(clearFilters(''));
    };

    const filterClick = () => {
        setFilterOpen(true);
    };

    const handlePageChange = page => {
        dispatch(fetchPeoples(page, convertFiltersToString()));
    };

    const convertFiltersToString = () => {
        const obj = {};
        selectedFilters.forEach(f => {
            if (!obj[f.type]) {
                obj[f.type] = [f.value];
            } else {
                obj[f.type].push(f.value);
            }
        });
        return JSON.stringify(obj);
    };

    useEffect(() => {
        if (JSON.stringify(filterCache) !== JSON.stringify(selectedFilters)) {
            setFilterCache(selectedFilters);
        }
    }, [selectedFilters]);

    const isFirstRun = useRef(true);
    const router = useRouter();

    useEffect(() => {
        isFirstRun.current = true;
        return () => {
            setPeopleCache(peoples);
            dispatch(clearFilters(''));
        };
    }, [router.pathname]);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        dispatch(fetchPeoples(0, convertFiltersToString()));
    }, [filterCache]);

    const startLoading = useRef(false);

    useEffect(() => {
        if (peoplesLoading) {
            startLoading.current = true;
        }
        if (
            startLoading.current &&
            !peoplesLoading &&
            peoplesFromApi.data !== undefined &&
            JSON.stringify(peoplesFromApi) !== JSON.stringify(peopleCache)
        ) {
            setPeopleCache(peoplesFromApi);
            // scrollToTargetOffset(elRef.current, isDesktop ? 110 : 80);
            scrollToTargetOffset(elRef.current, isDesktop ? 110 : 80);
            startLoading.current = false;
        }
    }, [peoplesLoading]);

    const isFirstLoad = useRef(true);

    useEffect(() => {
        if (isFirstLoad.current) {
            filters.map(type => {
                if (router.query[type.type] !== undefined) {
                    const queryFilters = router.query[type.type].split(',');
                    queryFilters.map(queryFilter => {
                        const hasFilter = type.data.filter(f => f.value === queryFilter);
                        if (hasFilter.length > 0) {
                            const obj = { type: type.type, value: hasFilter[0].value, label: hasFilter[0].label };
                            dispatch(addFilter(obj));
                        }
                        return false;
                    });
                }
                return false;
            });
            isFirstLoad.current = false;
            return;
        }
        return () => {
            // clearAllBodyScrollLocks();
        };
    });

    useEffect(() => {
        // setPageLoaded(true);
        // window.addEventListener('resize', () => setFilterOpen(false));
        return () => {
            // clearAllBodyScrollLocks();
        };
    });

    useEffect(() => {
        if (filterOpen) {
            setBodyNoScroll(true);
        } else {
            setBodyNoScroll(false);
        }
        return () => {
            setBodyNoScroll(false);
        };
    }, [filterOpen]);

    const isDesktop = useMediaQuery('only screen and (min-width: 768px)');

    useEffect(() => {
        if (isDesktop) {
            setFilterOpen(false);
        }
    }, [isDesktop]);

    const handleSortChange = value => {
        const val = typeof value === 'string' ? value : value.target.value;
        if (val !== sort) {
            setSort(val);
        }
    };

    const isFirstSort = useRef(true);

    useEffect(() => {
        if (isFirstSort.current) {
            isFirstSort.current = false;
            return;
        }
        dispatch(fetchPeoples(0, convertFiltersToString(), sort));
    }, [sort]);

    const RenderSelectedFilters = () => (
        <>
            {selectedFilters.map((filter, i) => (
                <button
                    key={i}
                    type='button'
                    data-type={filter.type}
                    data-label={filter.label}
                    data-value={filter.value}
                    className={style.filterButton}
                    onClick={removeFilter}
                >
                    {filter.label}
                    <span className={style.cross} />
                </button>
            ))}
        </>
    );
    return (
        <div className={style.el} ref={elRef}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.filters}>
                    <div className={style.filtersMobile}>
                        {selectedFilters.length > 0 ? (
                            <button type='button' className={style.clearButton} onClick={clearFiltersClick}>
                                <span className='icon-close' />
                                {copies?.clear}
                            </button>
                        ) : (
                            <div />
                        )}
                        <button type='button' className={style.filterButton} onClick={filterClick}>
                            <span className='icon-filter' />
                            {copies?.filter}
                        </button>
                    </div>
                    <div className={[style.filtersWrapper, filterOpen ? style.open : ''].join(' ')} ref={filtersRef}>
                        <div className={style.filterHeadMobile}>
                            <button className={style.backBtn} onClick={() => setFilterOpen(false)}>
                                {/* <span className='icon-arrow-right' /> */}
                            </button>
                            <h5>{title}</h5>
                            <button className={style.closeBtn} onClick={() => setFilterOpen(false)}>
                                <span className='icon-close' />
                            </button>
                        </div>
                        {selectedFilters.length > 0 && (
                            <div className={[style.selectedFilters, 'mobile'].join(' ')}>
                                <RenderSelectedFilters />
                            </div>
                        )}
                        {filters.map((item, i) => (
                            <div
                                className={style.filterSelectWrap}
                                style={{ width: `${100 / filters.length}%` }}
                                key={item.type}
                            >
                                <FilterSelect
                                    {...item}
                                    handleSelect={handleFilterSelect}
                                    selectedFilters={Array.from(selectedFilters).filter(f => f.type === item.type)}
                                />
                            </div>
                        ))}
                        <div className={style.filterBottomMobile}>
                            <button className={style.applyBtn} onClick={() => setFilterOpen(false)}>
                                {copies?.apply}
                            </button>
                        </div>
                    </div>
                </div>
                <div className={style.filtersSort}>
                    <div className={[style.selectedFilters, 'desktop'].join(' ')}>
                        {selectedFilters.length > 0 && <RenderSelectedFilters />}
                    </div>
                    <div className={[style.numberTeamMembersMobile, 'mobile'].join(' ')}>
                        {copies?.ofTeamMembers && (
                            <>
                                <span className={style.number}>{peopleCache.totalItems}</span> {copies?.ofTeamMembers}
                            </>
                        )}
                    </div>
                    <div className={style.sortSelect}>
                        <CustomSelect active={sort} data={sortSelect} handleSelect={handleSortChange} />
                    </div>
                </div>
                <div className={[style.numberTeamMembers, 'desktop'].join(' ')}>
                    <div>
                        {copies?.ofTeamMembers && (
                            <>
                                <span className={style.number}>{peopleCache.totalItems}</span> {copies?.ofTeamMembers}
                            </>
                        )}
                    </div>
                    {selectedFilters.length > 0 && (
                        <button
                            type='button'
                            className={[style.clearButton, 'desktop'].join(' ')}
                            onClick={clearFiltersClick}
                        >
                            <span className='icon-close' />
                            {copies?.clearFilters}
                        </button>
                    )}
                </div>
                <div className={style.peopleWrapper}>
                    {peopleCache.data.length > 0 ? (
                        peopleCache.data.map((item, i) => (
                            <div key={`people${i}`} className={style.peopleCard}>
                                <PeopleCard {...item} selectedFilters={selectedFilters} />
                            </div>
                        ))
                    ) : (
                        <div className={style.noResults}>{copies?.noResultMessage}</div>
                    )}
                </div>
                {peopleCache.totalPages > 0 && (
                    <Pagination
                        total={peopleCache.totalPages + 1}
                        active={peopleCache.currentPage}
                        changePage={handlePageChange}
                    />
                )}
            </div>
        </div>
    );
};

PeopleListing.propTypes = {
    title: PropTypes.string,
    filters: PropTypes.array,
    peoples: PropTypes.object,
    copies: PropTypes.object,
};

export default PeopleListing;

import React from 'react';
import PropTypes from 'prop-types';

import { SmartLink } from '../SmartLink';

import style from './PeopleCard.module.scss';

const PeopleCard = ({ name, title, link, filters, image }) => {
    const filterNames = filters
        ?.map(type => type.value.map(value => [type.type, value.value].join('-')).join(' '))
        .join(' ');
    const imageUrl = image?.url !== '' ? image.url : '/images/default.png';
    return (
        <SmartLink href={link.url} className={[style.el, filterNames].join(' ')}>
            <div className={style.image}>
                <div className={style.imageWrap}>
                    <img src={imageUrl} alt={image.alt} />
                </div>
            </div>
            <div className={style.content}>
                <h4 className={style.name}>{name}</h4>
                <p className={style.title}>{title}</p>
            </div>
        </SmartLink>
    );
};

PeopleCard.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.object,
    filters: PropTypes.array,
    selectedFilters: PropTypes.array,
    image: PropTypes.object,
};

export default PeopleCard;

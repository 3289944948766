import React from 'react';
import PropTypes from 'prop-types';

import style from './InnerPageHeader.module.scss';
import { useDispatch } from 'react-redux';

import { openPlayerModal, updatePlayerLink } from '../../redux/modal/playerModal.action';
// const OverlayImage = () => (
//     <svg xmlns='http://www.w3.org/2000/svg' width='592' height='316' fill='none' viewBox='0 0 592 316'>
//         <path
//             fill='#E67C25'
//             d='M156.358 264.462c34.371-57.137-51.19-109.584-87.645-53.737L0 316h122.75c15.557-23.776 27.591-42.157 31.319-47.932.821-1.202 1.555-2.404 2.289-3.606zM473.946 76.75c33.021-57.401-52.276-108.353-88.261-52.77L196.717 316H319.79C387.24 211.751 462.734 94.955 471.657 80.561c.792-1.26 1.555-2.55 2.289-3.811zM591.999 11.316L394.637 316h122.28c23.452-36.176 49.047-75.665 75.082-115.799V11.316z'
//         />
//     </svg>
// );

const InnerPageHeader = props => {
    const { title, description, image, video } = props;

    const dispatch = useDispatch();

    const openPlayer = link => {
        dispatch(openPlayerModal());
        dispatch(updatePlayerLink(link));
    };
    return (
        <div className={style.el}>
            <div className={style.content}>
                <div className={[style.container, 'container'].join(' ')}>
                    <div className={style.contentWrapper}>
                        <h1 className={style.title} dangerouslySetInnerHTML={{ __html: title }} />
                        <p className={style.description} dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                </div>
            </div>
            <div
                className={style.image}
                style={{
                    backgroundImage: `url(${image.url})`,
                }}
            >
                {video ? (
                    <button type='button' className={style.playButton} onClick={() => openPlayer(video)}>
                        <img src='/images/people/play.png' alt='' />
                    </button>
                ) : (
                    ' '
                )}

                {/* <div className={style.overlay}>
                    <OverlayImage />
                </div> */}
            </div>
        </div>
    );
};

InnerPageHeader.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    video: PropTypes.string,
};
export default InnerPageHeader;

InnerPageHeader.defaultProps = {
    title: 'Meet Our People',
    description: '360+ individuals who challenge everyday thinking to multiply potential.',
    image: {
        url: 'https://via.placeholder.com/900x900.jpg',
        alt: 'image-1',
    },
};
